<template>
  <a-modal
    class="modal-basic min-h-72"
    :visible="true"
    :maskClosable="false"
    :centered="true"
    :closable="false"
    :footer="null"
    :zIndex="20000"
  >
    <h1>Maintenance in Progress</h1>
    <p>
      Our site is currently unavailable as we are performing scheduled
      maintenance.{{ maintenanceModeMessage }}
    </p>
    <div class="flex flex-row">
      <div class="btn secondary" @click="goToOW">
        Use table-based itinerary builder
      </div>
    </div>
  </a-modal>
</template>
<script lang="ts">
import { useStore } from '@/store';
import * as config from 'config';

const store = useStore();
export default {
  name: 'Maintenance',
  data() {
    return {
      maintenanceModeMessage: store.state.ldClient?.variation(
        'maintenance-mode-message',
        ''
      ),
    };
  },
  methods: {
    goToOW: () => {
      window.location.href = config.ZEN3_URL;
    },
  },
};
</script>
